import { useState, useCallback, useContext } from 'react';
import { TopBar } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { AuthContext } from '../providers/AuthProvider';
import { usersApi } from '../redux/services/users'


const Topbar = ({ toggleMobileNavigation }) => {
  const { handleLogout } = useContext(AuthContext);
  const { data: usersData } = useSelector(state => usersApi.endpoints.getMe.select()(state))
  const [userMenuActive, setUserMenuActive] = useState(false);
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );

  return (
    <TopBar
      showNavigationToggle
      onNavigationToggle={toggleMobileNavigation}
      userMenu={
        <TopBar.UserMenu
          actions={[
            {
              items: [{ content: 'Logout', onAction: () => handleLogout() }],
            },
          ]}
          name={usersData?.full_name}
          initials={usersData?.full_name?.charAt(0)}
          open={userMenuActive}
          onToggle={toggleUserMenuActive}
        />
      }
    />
  )
}

export default Topbar