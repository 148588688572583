import { lazy } from "react";

export const publicRoutes = [
  {
    path: "/login",
    exact: true,
    element: lazy(() => import("./pages/Login")),
  },
];

export const protectedRoutes = [
  {
    path: "/orderview",
    exact: true,
    element: lazy(() => import("./pages/OrderView")),
  },
  {
    path: "/customers",
    exact: true,
    element: lazy(() => import("./pages/customers/Customers")),
  },
  {
    path: `/customers/:id`,
    exact: true,
    element: lazy(() => import("./pages/customers/CustomerView")),
  },
  {
    path: `/customer/edit/:id`,
    exact: true,
    element: lazy(() => import("./pages/customers/EditMeasurement")),
  },

  {
    path: "/patterns",
    exact: true,
    element: lazy(() => import("./pages/Patterns")),
  },
  {
    path: "/createpattern",
    exact: true,
    element: lazy(() => import("./pages/CreatePattern")),
  },

  {
    path: "/alterations",
    exact: true,
    element: lazy(() => import("./pages/Alterations/Alterations")),
  },
  {
    path: `/alterations/:id`,
    exact: true,
    element: lazy(() => import("./pages/Alterations/SingleAlteration")),
  },
  {
    path: `/statuses/alterations`,
    exact: true,
    element: lazy(() => import("./pages/Alterations/AlterationStatuses")),
  },
  {
    path: `/statuses/alterations/create`,
    exact: true,
    element: lazy(() => import("./pages/Alterations/CreateAlterationStatus")),
  },
  {
    path: `/statuses/alterations/:id`,
    exact: true,
    element: lazy(() => import("./pages/Alterations/SingleAlterationStatus")),
  },
  {
    path: `/sequence/alterations`,
    exact: true,
    element: lazy(() => import("./pages/Alterations/AlterationStatusSequence")),
  },

  {
    path: "/orders",
    exact: true,
    element: lazy(() => import("./pages/Orders/Orders")),
  },
  {
    path: `/orders/:id`,
    exact: true,
    element: lazy(() => import("./pages/Orders/OrderView")),
  },
  {
    path: `/statuses/orders`,
    exact: true,
    element: lazy(() => import("./pages/Orders/OrderStatuses")),
  },
  {
    path: `/statuses/orders/create`,
    exact: true,
    element: lazy(() => import("./pages/Orders/CreateOrderStatus")),
  },
  {
    path: `/statuses/orders/:id`,
    exact: true,
    element: lazy(() => import("./pages/Orders/SingleOrderStatus")),
  },
  {
    path: `/sequence/orders`,
    exact: true,
    element: lazy(() => import("./pages/Orders/OrderSequence")),
  },

  {
    path: "/products",
    exact: true,
    element: lazy(() => import("./pages/Products")),
  },
  {
    path: "/form-responses",
    exact: true,
    element: lazy(() => import("./pages/FormResponses")),
  },
  {
    path: "/form-responses/:id",
    exact: true,
    element: lazy(() => import("./pages/SingleFormResponse")),
  },
  {
    path: "/custom-date-range",
    exact: true,
    element: lazy(() => import("./pages/CustomDateRange")),
  },
  {
    path: "/single-day",
    exact: true,
    element: lazy(() => import("./pages/SingleDay")),
  },
  {
    path: "/monthly-breakdown",
    exact: true,
    element: lazy(() => import("./pages/MonthlyBreakdown")),
  },
  {
    path: "/dashboard",
    exact: true,
    element: lazy(() => import("./pages/Dashboard")),
  },
  {
    path: "/",
    exact: true,
    element: lazy(() => import("./pages/Dashboard")),
  },

  {
    path: "/qc",
    exact: true,
    element: lazy(() => import("./pages/QC/QC")),
  },
  {
    path: "/qc/create",
    exact: true,
    element: lazy(() => import("./pages/QC/CreateQC")),
  },
  {
    path: "/qc/edit/:id",
    exact: true,
    element: lazy(() => import("./pages/QC/QCForm")),
  },

  {
    path: "/users",
    exact: true,
    element: lazy(() => import("./pages/users/Users")),
  },
  {
    path: "/users/create",
    exact: true,
    element: lazy(() => import("./pages/users/UserForm")),
  },
  {
    path: "/users/edit/:id",
    exact: true,
    element: lazy(() => import("./pages/users/UserForm")),
  },
  {
    path: "/settings",
    exact: true,
    element: lazy(() => import("./pages/Settings")),
  },
];
