import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import AuthProvider from "./providers/AuthProvider";
import ProtectedLayout from "./components/layouts/ProtectedLayout";
import PublicLayout from "./components/layouts/PublicLayout";

import { publicRoutes, protectedRoutes } from "./routes";
import SkeletonLoader from "./components/SkeletonLoader";

const Router = () => {
  return (
    <AuthProvider>
      <Routes>
        {/** Public Routes **/}
        <Route element={<PublicLayout />}>
          {publicRoutes.map((route, i) => {
            return (
              <Route
                key={`public-${i}`}
                path={route.path}
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <route.element />
                  </Suspense>
                }
              />
            );
          })}
        </Route>

        {/** Protected Routes **/}
        <Route path="/" element={<ProtectedLayout />}>
          {protectedRoutes.map((route, i) => {
            return (
              <Route
                key={`protected-${i}`}
                path={route.path}
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <route.element />
                  </Suspense>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default Router;
