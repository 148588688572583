import { useState, useCallback, useContext } from 'react';
import { Frame, Loading } from '@shopify/polaris';
import { Outlet, useNavigate } from 'react-router-dom';

import Topbar from '../Topbar';
import Sidebar from '../Sidebar';
import { AuthContext } from '../../providers/AuthProvider'

import { useEffect } from 'react';

const ProtectedLayout = () => {
  let navigate = useNavigate();
  const { authState, isAuthenticated } = useContext(AuthContext)
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { replace: true })
    }
  }, [isAuthenticated, navigate])


  return (
    <Frame
      logo={{
        width: 220,
        topBarSource:
          'https://cdn.shopify.com/s/files/1/0423/3576/4634/files/TPP_Logo-1_220x@2x.png?v=1594744854',
        url: '/alterations',
        contextualSaveBarSource:
          'https://cdn.shopify.com/s/files/1/0423/3576/4634/files/TPP_Logo-1_220x@2x.png?v=1594744854',
      }}
      navigation={(<Sidebar />)}
      topBar={<Topbar toggleMobileNavigation={toggleMobileNavigationActive} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
    >
      <Outlet />
      {
        authState.authenticated && <Outlet />
      }
    </Frame>
  )
}

export default ProtectedLayout;