import { createApi } from '@reduxjs/toolkit/query/react';
import apiBaseQuery from './axiosBaseQuery'
export const STATUSES_API_REDUCER_KEY = 'statusesApi';


export const statusesApi = createApi({
    reducerPath: STATUSES_API_REDUCER_KEY,
    baseQuery: apiBaseQuery,
    tagTypes: ['status'],
    endpoints: (builder) => ({
        getAllStatuses: builder.query({
            query: (page) => ({
                url: `admin/alterations/statuses?page=${page}&limit=100`,
            }),
            providesTags: ['status'],
        }),
        getStatusById: builder.query({
            query: (id) => ({
                url: `admin/alterations/statuses/${id}`
            }),
            providesTags: ["status"],
        }),
        createStatus: builder.mutation({
            query: (body) => ({
                url: `admin/alterations/statuses`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['status'],
        }),
        updateStatus: builder.mutation({
            query: ({ id, ...rest }) => ({
                url: `admin/alterations/statuses/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['status'],
        }),
        patchStatus: builder.mutation({
            query: (body, id) => ({
                url: `admin/alterations/statuses/${id}`,
                method: 'PATCH',
                body
            })
        }),
        bulkUpdateStatus: builder.mutation({
            query: (body) => ({
                url: `admin/alterations/statuses/bulk-update`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['status'],
        }),
        deleteStatus: builder.mutation({
            query: (id) => ({
                url: `admin/alterations/statuses/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['status'],
        }),
    })
});

export const {
    useGetAllStatusesQuery,
    useGetStatusByIdQuery,
    useCreateStatusMutation,
    useUpdateStatusMutation,
    usePatchStatusMutation,
    useBulkUpdateStatusMutation,
    useDeleteStatusMutation
} = statusesApi;