import { createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetSessionQuery, useLogoutMutation } from "../redux/services/auth";
import { setAccessToken } from "../redux/slices/authSlice";
import { useGetMeQuery, usersApi } from "../redux/services/users";
import { isEmpty } from "lodash";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    data: sessionData,
    isLoading,
    isFetching,
    error,
  } = useGetSessionQuery();
  const { data } = useGetMeQuery();
  const [logout] = useLogoutMutation();
  const authState = useSelector((store) => store?.auth);

  const isAdmin = data?.groups?.[0] === 3 || data?.is_superuser;

  const isAlterations = data?.groups?.[0] === 5;

  const isOperations = data?.groups?.[0] === 4;

  const isCustomerService = data?.groups?.[0] === 1;
  console.log({
    isAdmin,
    isAlterations,
    isOperations,
    isCustomerService,
  });
  const handleLogin = () => {};
  const handleLogout = () => {
    logout();
    dispatch(setAccessToken(null));
  };

  useEffect(() => {
    if (sessionData?.data?.access_token) {
      dispatch(setAccessToken(sessionData?.data?.access_token));
      dispatch(usersApi.endpoints.getMe.initiate());
    }
  }, [sessionData, dispatch]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        isAuthenticated:
          !isEmpty(authState?.accessToken) || error === undefined,
        handleLogin,
        handleLogout,
        isAdmin,
        isAlterations,
        isOperations,
        isCustomerService,
      }}
    >
      {(isLoading || isFetching) && "Loading Session"}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
