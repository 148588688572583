import { createApi } from "@reduxjs/toolkit/query/react";

import apiBaseQuery from "./axiosBaseQuery";

export const USERS_API_REDUCER_KEY = "usersApi";

export const usersApi = createApi({
  reducerPath: USERS_API_REDUCER_KEY,
  baseQuery: apiBaseQuery,
  tagTypes: ["user"],
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => ({
        url: "users/me/",
      }),
      providesTags: ["user"],
    }),
    getUsers: builder.query({
      query: ({ page }) => ({
        url: `users/?page=${page}&limit=100`,
      }),
      providesTags: ["user"],
    }),
    getSingleUser: builder.query({
      query: (id) => {
        if (!id) {
          return;
        }
        return {
          url: `users/${id}`,
        };
      },
      providesTags: ["user"],
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "users/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...rest }) => {
        return {
          url: `users/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: ["user"],
    }),
    deleteUser: builder.mutation({
      query: (id) => {
        return {
          url: `users/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["user"],
    }),
    getGroups: builder.query({
      query: () => {
        return {
          url: `users/groups/`,
        };
      },
    }),
    getShopMetadata: builder.query({
      query: () => {
        return {
          url: `admin/shopify/shop/metafields?namespace=tpp_custom_pg_list&key=cod_threshold`,
        };
      },
    }),
    updateShopMetaData: builder.mutation({
      query: (body) => {
        return {
          url: `admin/shopify/shop/metafields`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetSingleUserQuery,
  useGetGroupsQuery,
  useDeleteUserMutation,
  useGetShopMetadataQuery,
  useUpdateShopMetaDataMutation,
} = usersApi;
