import { createApi } from "@reduxjs/toolkit/query/react";

import apiBaseQuery from "./axiosBaseQuery";

export const CUSTOMERS_API_REDUCER_KEY = "customersApi";

export const customersApi = createApi({
  reducerPath: CUSTOMERS_API_REDUCER_KEY,
  baseQuery: apiBaseQuery,
  tagTypes: ["customer"],
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: ({ page, q, sort }) => {
        return {
          url: sort
            ? `admin/oms/customers?page=${page}&q=${q}&sort=${sort}&limit=100`
            : `admin/oms/customers?page=${page}&q=${q}&limit=100`,
        };
      },
      providesTags: ["customer"],
    }),
    getCustomerById: builder.query({
      query: (id) => ({
        url: `admin/oms/customers/${id}`,
      }),
      providesTags: ["customer"],
    }),
    getCustomerTemplate: builder.query({
      query: (id) => {
        if (!id) {
          return;
        }
        return {
          url: `admin/oms/customers/template/${id}`,
        };
      },
      providesTags: ["customer"],
    }),
    updateCustomerTemplate: builder.mutation({
      query: ({ id, ...rest }) => {
        if (!id) {
          return;
        }
        return {
          url: `admin/oms/customers/template/${id}`,
          method: "PUT",
          body: rest,
        };
      },
      invalidatesTags: ["customer"],
    }),
    getTimelineByCustomerId: builder.query({
      query: ({ id, measurementId }) => {
        if (!id || !measurementId) {
          return;
        }
        return {
          url: measurementId
            ? `admin/oms/customers/${id}/measurements/${measurementId}/histories`
            : "",
        };
      },
      providesTags: ["customer"],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useGetCustomerByIdQuery,
  useGetTimelineByCustomerIdQuery,
  useGetCustomerTemplateQuery,
  useUpdateCustomerTemplateMutation,
} = customersApi;
