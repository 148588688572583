import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import apiBaseQuery from './axiosBaseQuery';

export const AUTH_API_REDUCER_KEY = 'authApi';

export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery: apiBaseQuery,
  endpoints: (builder) => ({
    getSession: builder.query({
      query: () => ({
        url: 'auth/session'
      })
    }),
    login: builder.mutation({
      query: (body) => {
        return {
          url: 'auth/login',
          method: 'POST',
          body
        }
      }
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      })
    })
  })
});

export const { useLoginMutation, useLogoutMutation, useGetSessionQuery } = authApi;