import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "react-vertical-timeline-component/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import ShopifyLink from "./components/ShopifyLink";
import Router from "./Router";
import { store } from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppProvider i18n={enTranslations} linkComponent={ShopifyLink}>
          <Router />
        </AppProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
