import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./services/auth";
import { usersApi } from "./services/users";
import { alterationsApi } from "./services/alterations";
import { customersApi } from "./services/customers";
import { statusesApi } from "./services/statuses";
import authReducer from "./slices/authSlice";
import { orderFeedbackApi } from "./services/orderFeedback";
import { ordersApi } from "./services/orders";
import { qcApi } from "./services/qc";

const setUpStore = () => {
  const store = configureStore({
    reducer: {
      [authApi.reducerPath]: authApi.reducer,
      [usersApi.reducerPath]: usersApi.reducer,
      [alterationsApi.reducerPath]: alterationsApi.reducer,
      [customersApi.reducerPath]: customersApi.reducer,
      [statusesApi.reducerPath]: statusesApi.reducer,
      [orderFeedbackApi.reducerPath]: orderFeedbackApi.reducer,
      [ordersApi.reducerPath]: ordersApi.reducer,
      [qcApi.reducerPath]: qcApi.reducer,
      auth: authReducer,
    },
    middleware: (getDM) => [
      ...getDM(),
      authApi.middleware,
      usersApi.middleware,
      alterationsApi.middleware,
      customersApi.middleware,
      statusesApi.middleware,
      orderFeedbackApi.middleware,
      ordersApi.middleware,
      qcApi.middleware,
    ],
  });
  setupListeners(store.dispatch);
  return store;
};

export const store = setUpStore();
