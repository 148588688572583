import { createApi } from "@reduxjs/toolkit/query/react";

import apiBaseQuery from "./axiosBaseQuery";

export const QC_API_REDUCER_KEY = "qcApi";

export const qcApi = createApi({
  reducerPath: QC_API_REDUCER_KEY,
  baseQuery: apiBaseQuery,
  tagTypes: ["qc"],
  endpoints: (builder) => ({
    getQc: builder.query({
      query: ({ page, q, sort }) => ({
        url: `admin/oms/quality-control-orders/?page=${page || 1}&q=${
          q || ""
        }&limit=10`,
      }),
      providesTags: ["qc"],
    }),
    getSingleQc: builder.query({
      query: (id) => {
        if (!id) {
          return;
        }
        return {
          url: `admin/oms/quality-control-orders/${id}/`,
        };
      },
      providesTags: ["qc"],
    }),
    getQcHistory: builder.query({
      query: (id) => {
        if (!id) {
          return;
        }
        return {
          url: `admin/oms/quality-control-orders/${id}/histories`,
        };
      },
      providesTags: ["qc"],
    }),
    createQC: builder.mutation({
      query: (body) => ({
        url: `admin/oms/quality-control-orders/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["qc"],
    }),
    updateQC: builder.mutation({
      query: ({ id, body }) => ({
        url: `admin/oms/quality-control-orders/${id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["qc"],
    }),
    deleteQC: builder.mutation({
      query: (id) => ({
        url: `admin/oms/quality-control-orders/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["qc"],
    }),
  }),
});

export const {
  useCreateQCMutation,
  useGetQcQuery,
  useGetSingleQcQuery,
  useUpdateQCMutation,
  useDeleteQCMutation,
  useGetQcHistoryQuery,
} = qcApi;
