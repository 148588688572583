import { createApi } from '@reduxjs/toolkit/query/react';
import apiBaseQuery from './axiosBaseQuery';

export const FEEDBACK_REDUCER_KEY = 'orderFeedbackApi';

export const orderFeedbackApi = createApi({
  reducerPath: FEEDBACK_REDUCER_KEY,
  baseQuery: apiBaseQuery,
  endpoints: (builder) => ({
    getOrderFeedbacks: builder.query({
      query: ({ page, limit, q }) => ({
        url: `admin/oms/order-feedbacks?page=${page}&q=${q}&limit=${limit}`
      })
    }),
    singleFeedback: builder.query({
      query: (id) => ({
        url: `admin/oms/order-feedbacks/${id}`
      })
    }),
  })
});

export const { useGetOrderFeedbacksQuery, useSingleFeedbackQuery } = orderFeedbackApi;