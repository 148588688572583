import { createApi } from "@reduxjs/toolkit/query/react";
import apiBaseQuery from "./axiosBaseQuery";
export const ORDER_API_REDUCER_KEY = "ordersApi";

export const ordersApi = createApi({
  reducerPath: ORDER_API_REDUCER_KEY,
  baseQuery: apiBaseQuery,
  tagTypes: ["orders"],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ page, q, sort, status, limit }) => ({
        url: `admin/oms/orders?page=${page}&q=${q || ""}&sort=${
          sort || "-order_number"
        }&status=${status || ""}&limit=${limit || 100}`,
      }),
      providesTags: ["order"],
    }),

    getOrderById: builder.query({
      query: (id) => {
        if (!id) {
          return;
        }
        return {
          url: `admin/oms/orders/${id}`,
        };
      },
    }),

    getOrderItemTimeLineById: builder.query({
      query: ({ id, lineId }) => {
        if (!lineId) {
          return;
        }
        return {
          url: `admin/oms/orders/${id}/line/${lineId}/history`,
        };
      },
    }),

    getOrderStatuses: builder.query({
      query: (page) => ({
        url: `admin/oms/order-statuses?page=${page}&limit=100`,
      }),
      providesTags: ["order"],
    }),

    getSingleOrderStatus: builder.query({
      query: (id) => ({
        url: `admin/oms/order-statuses/${id}`,
      }),
      providesTags: ["order"],
    }),

    createOrderStatus: builder.mutation({
      query: (body) => ({
        url: `admin/oms/order-statuses`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["order"],
    }),

    updateOrderStatus: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `admin/oms/order-statuses/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["order"],
    }),

    deleteOrderStatus: builder.mutation({
      query: (id) => ({
        url: `admin/oms/order-statuses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["order"],
    }),

    sequenceUpdateOrderStatus: builder.mutation({
      query: (body) => ({
        url: `admin/oms/order-statuses/bulk-update`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["order"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useGetOrderItemTimeLineByIdQuery,
  useGetOrderStatusesQuery,
  useGetSingleOrderStatusQuery,
  useCreateOrderStatusMutation,
  useDeleteOrderStatusMutation,
  useUpdateOrderStatusMutation,
  useSequenceUpdateOrderStatusMutation,
} = ordersApi;
