import { createApi } from "@reduxjs/toolkit/query/react";
import apiBaseQuery from "./axiosBaseQuery";
export const ALTERATIONS_API_REDUCER_KEY = "alterationsApi";

export const alterationsApi = createApi({
  reducerPath: ALTERATIONS_API_REDUCER_KEY,
  baseQuery: apiBaseQuery,
  tagTypes: ["alterations"],
  endpoints: (builder) => ({
    getAlterations: builder.query({
      query: ({ page, status, q, sort }) => ({
        url: `admin/alterations/items?page=${page}&status=${status}&q=${q}&sort=${sort}&limit=100`,
      }),
      providesTags: ["alterations"],
    }),
    getAlterationById: builder.query({
      query: (id) => ({
        url: `admin/alterations/items/${id}`,
      }),
      providesTags: ["alterations"],
    }),
    getStatusCountByRange: builder.query({
      query: ({ start_date, end_date, limit }) => ({
        url: `admin/alterations/analytics/status/range?start_date=${start_date}&end_date=${end_date}&limit=${limit}`,
      }),
      providesTags: ["alterations"],
    }),
    getStatusCountByDate: builder.query({
      query: ({ current_date, limit }) => ({
        url: `admin/alterations/analytics/status/day?current_date=${current_date}&limit=${limit}`,
      }),
      providesTags: ["alterations"],
    }),
    getAlterationStats: builder.query({
      query: () => ({
        url: `admin/alterations/analytics/current-stats`,
      }),
      providesTags: ["alterations"],
    }),
    getStatusByOrderProps: builder.query({
      query: ({ year, month }) => ({
        url: `admin/alterations/analytics/by-order-props?year=${year}&month=${month}`,
      }),
      providesTags: ["alterations"],
    }),
    getStatusByAlterProps: builder.query({
      query: ({ year, month }) => ({
        url: `admin/alterations/analytics/by-alteration-props?year=${year}&month=${month}`,
      }),
      providesTags: ["alterations"],
    }),
    getAlterationCustomerMeasurement: builder.query({
      query: ({ customer, template }) => {
        if (!customer || !template) {
          return;
        }
        return {
          url: `admin/alterations/customer/${customer}/measurement/${template}`,
        };
      },
      providesTags: ["alterations"],
    }),
    updateAlterCustomerTemplate: builder.mutation({
      query: ({ order_line_id, ...rest }) => {
        return {
          url: `admin/alterations/customer/${order_line_id}/`,
          method: "PUT",
          body: rest,
        };
      },
      invalidatesTags: ["alterations"],
    }),
    updateAlterationStatus: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `admin/alterations/items/${id}/status`,
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["alterations"],
    }),
    addNote: builder.mutation({
      query: ({ id, body }) => ({
        url: `admin/alterations/items/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["alterations"],
    }),
    bulkUpdateAlterationStatus: builder.mutation({
      query: (body) => ({
        url: `admin/alterations/items/bulk-actions/update-status`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["alterations"],
    }),
  }),
});

export const {
  useGetAlterationsQuery,
  useGetAlterationByIdQuery,
  useUpdateAlterationStatusMutation,
  useBulkUpdateAlterationStatusMutation,
  useAddNoteMutation,
  useGetStatusByOrderPropsQuery,
  useGetStatusCountByDateQuery,
  useGetStatusCountByRangeQuery,
  useGetAlterationStatsQuery,
  useGetStatusByAlterPropsQuery,
  useGetAlterationCustomerMeasurementQuery,
  useUpdateAlterCustomerTemplateMutation,
} = alterationsApi;
